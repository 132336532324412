.title {
  .h1 {
    font-family: Great Vibes, cursive;
    font-size: 3rem;
    color: $primary;
  }

  .separator {
    width: 25%;
  }

  @media only screen and (max-width: $mobile) {
    .h1 {
      font-size: 2.5rem;
    }

    .separator {
      width: 75%;
    }
  }
}
