.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fefefe;

  img {
    width: 10%;

    @media only screen and (max-width: $mobile) {
      width: 30%;
    }
  }
}
